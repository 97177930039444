<script setup>
import { Link, router, usePage } from "@inertiajs/vue3";
import { computed, ref } from "vue";

import ApplicationLogo from "@/Components/ApplicationLogo.vue";

import FooterLinks from "./LayoutComponents/FooterLinks.vue";
import NavigationLinks from "./LayoutComponents/NavigationLinks.vue";
import UserProfile from "./LayoutComponents/UserProfile.vue";

defineProps({
    title: String,
});

const user = computed(() => usePage().props.auth.user);
const currentRoute = computed(() => usePage().props.current_route);
const mobileMenuOpen = ref(false);
const popover = ref(null);

const toggleMobileMenu = () => {
    mobileMenuOpen.value = !mobileMenuOpen.value;
};

const toggle = (event) => {
    popover.value?.toggle(event);
};

const logout = () => {
    router.post(route("super.logout"));
};

const navItems = [
    { name: "Super Dashboard", route: "super.dashboard", icon: "pi-home" },
    { name: "Organizations", route: "super.organizations.index", icon: "pi-building" },
    { name: "Users", route: "super.users.index", icon: "pi-users" },
    { name: "Org Hosts", route: "super.org-hosts.index", icon: "pi-server" },
    { name: "Features", route: "super.features.index", icon: "pi-flag" },
    { name: "ETLs", route: "super.etls.index", icon: "pi-arrow-right-arrow-left" },
];

addEventListener("load", () =>
    setTimeout(() => {
        dispatchEvent(new Event("vite:prefetch"));
    }, 3000)
);
</script>

<template>
    <!-- Desktop Sidebar -->
    <div class="fixed inset-y-0 left-0 w-64 max-lg:hidden bg-cerbo-primary">
        <nav class="flex h-full min-h-0 flex-col">
            <!-- Logo and User Profile -->
            <div class="flex flex-row items-center justify-between border-b border-primary p-4">
                <Link v-ripple :href="route('dashboard')" class="p-ripple">
                    <ApplicationLogo class="block h-8 w-auto" />
                </Link>
            </div>

            <!-- Rest of the sidebar content -->
            <div class="flex flex-1 flex-col overflow-y-auto p-4 mt-6">
                <NavigationLinks :items="navItems" :current-route="currentRoute" />
                <FooterLinks :on-theme-toggle="toggleThemeButton" />
            </div>
            <div class="flex flex-col border-t border-primary">
                <UserProfile :user="user" :on-toggle="toggle" :on-logout="logout" />
            </div>
        </nav>
    </div>

    <!-- Mobile Menu Button -->
    <header class="flex items-center px-4 lg:hidden">
        <button
            type="button"
            class="fixed top-4 left-4 z-40 rounded-md p-2 text-primary"
            @click="toggleMobileMenu"
        >
            <svg
                class="h-6 w-6"
                fill="none"
                viewBox="0 0 24 24"
                stroke-width="1.5"
                stroke="currentColor"
            >
                <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5"
                />
            </svg>
        </button>
        <div class="min-w-0 flex-1">
            <nav class="flex flex-1 items-center gap-4 py-2.5">
                <div class="-ml-4 flex-1" />
                <div class="flex items-center gap-3">
                    <span class="relative">
                        <UserProfile
                            :user="user"
                            :minimal="true"
                            :on-toggle="toggle"
                            :on-logout="logout"
                        />
                    </span>
                </div>
            </nav>
        </div>
    </header>

    <!-- Mobile Navigation Menu -->
    <transition
        enter-active-class="transition-all duration-300 ease-out"
        enter-from-class="opacity-0 -translate-x-full"
        enter-to-class="opacity-100 translate-x-0"
        leave-active-class="transition-all duration-300 ease-in"
        leave-from-class="opacity-100 translate-x-0"
        leave-to-class="opacity-0 -translate-x-full"
    >
        <div v-show="mobileMenuOpen" class="fixed inset-0 top-0 z-40 lg:hidden">
            <!-- Backdrop -->
            <div
                class="fixed inset-0 bg-zinc-900/50 transition-opacity duration-300"
                @click="toggleMobileMenu"
            ></div>

            <!-- Mobile Menu Content -->
            <nav
                class="relative flex flex-col w-full h-full overflow-y-auto bg-cerbo-secondary px-4 py-6 transition-transform duration-300"
            >
                <button class="absolute right-4 top-4 p-2 text-primary" @click="toggleMobileMenu">
                    <svg
                        class="h-6 w-6"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke-width="1.5"
                        stroke="currentColor"
                    >
                        <path
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            d="M6 18L18 6M6 6l12 12"
                        />
                    </svg>
                </button>

                <NavigationLinks
                    :items="navItems"
                    :current-route="currentRoute"
                    :on-item-click="toggleMobileMenu"
                />

                <div class="mt-auto border-t border-primary pt-6">
                    <FooterLinks />
                </div>

                <div class="flex flex-col border-t border-primary">
                    <UserProfile
                        :user="$page.props.auth.user"
                        :popover="popover"
                        :on-toggle="toggle"
                        :on-logout="logout"
                    />
                </div>
            </nav>
        </div>
    </transition>
</template>
